@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&family=Share+Tech&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Share+Tech&display=swap");
/* @import url('https://fonts.googleapis.com/css?family=Poppins&family=Quicksand&family=Share+Tech+Mono:wght@600&display=swap'); */
*/ body,
html {
  font-family: "Helvetica Neue";
  /* font-family: 'Poppins', sans-serif;*/
  position: relative;
}
p,
ol {
  font-family: "Helvetica Neue";
  /*font-family: 'Poppins', sans-serif;*/
}
.selector-for-some-widget {
  processingimage-sizing: content-ProcessingImage;
}

#home {
  position: relative;
  background: url(../img/bgbanner.png) no-repeat center center;
  background-size: cover;
  background-attachment: fixed;
}
.homeLogo {
  position: absolute;
  top: 9em;
  left: 1rem;
}
.logoDIv .result img {
  width: 100%;
}

.homeContent h1 {
  color: #001559;
  text-transform: uppercase;
  border-left: 13px solid #e1241b;
  padding-left: 0.1em;
  /* font-size: 2.7rem; */
  font-size: 1.7rem;
  font-family: "Helvetica Neue";
}

#loginform .form-control {
  border: none;
  border-bottom: 1px solid #e1e1e1;
  border-radius: 17px;
  margin-bottom: 10px;
  font-size: 14px;
}

.harpicPlus {
  margin-right: -36px;
  font-size: 24px;
  font-family: sans-serif;
  font-weight: 1000;
  color: #e2261d;
  width: auto;
}
@media (max-width: 390px) {
  .harpicPlus {
    margin-right: -36px;
    font-size: 22px;
    font-family: sans-serif;
    font-weight: 1000;
    color: #e2261d;
    width: auto;
  }
}
@media (max-width: 330px) {
  .harpicPlus {
    margin-right: -36px;
    font-size: 18px;
    font-family: sans-serif;
    font-weight: 1000;
    color: #e2261d;
    width: auto;
  }
  
}
.newAnd{
   
  position: absolute;
  font-family: sans-serif;
  color: #083668;
  font-size:23px;
  font-weight: 1000;
  
}
@media (max-width: 390px) {
  .newAnd{
   
    position: absolute;
    font-family: sans-serif;
    color: #083668;
    font-size:21px;
    font-weight: 1000;
    
  }
}
@media (max-width: 360px) {
  .newAnd{
   
    position: absolute;
    font-family: sans-serif;
    color: #083668;
    font-size:20px;
    font-weight: 1000;
    
  }
}


@media (max-width: 344px) {
  .newAnd{
   
    position: absolute;
    font-family: sans-serif;
    color: #083668;
    font-size:20px;
    font-weight: 1000;
    text-align:left;
  }
  
}
@media (max-width: 332px) {
  .newAnd{
   
    position: absolute;
    font-family: sans-serif;
    color: #083668;
    font-size:23px;
    font-weight: 1000;
    text-align:left;
  }
  
}

.resultContent h1 {
  color: #fff;
  text-transform: uppercase;
  background-color: #0181c9;
  padding-left: 0.1em;
  font-size: 28pt;
  margin-bottom: 0.3rem;
}

.resultContent h4 {
  color: #fff;
  text-transform: uppercase;
  background-color: #0181c9;
  padding-left: 0.1em;
  font-size: 20pt;
  padding: 1rem;
}

.alignedp {
  padding-left: 1rem;
}
.resultContent p {
  color: #4e4e4e;
}
table {
  background-color: #eef3f9;
  border: 1px solid #0c4da2;
  width: 100%;
}
td {
  color: #001559;
  font-size: 17pt;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-width: 2px;
}
.screen-height {
  margin-bottom: 55px;
}
.screen-height-home {
  height: calc(100vh - 60px);
}
.homeContent p {
  padding-left: 25px;
}
.scanProduct {
  margin-top: 2em;
  font-family: "Helvetica Neue";
}

.scanProduct p {
  padding-left: 25px;
  margin-top: 1rem;
}
.scanProduct a {
  padding: 1em;
  text-transform: uppercase;
  /*background-color: #0181C9;*/
  background-color: #1a4e8c;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  display: block;
  text-align: center;
  font-size: 20px;
  border-radius: 32pt;
  font-family: "Helvetica Neue";
}
.scanProduct a:hover {
  color: #fff;
}
.scanProduct a img {
  padding-left: 1.6em;
}
.copyright {
  background: white;
  height: 50px;
  line-height: 50px;
  position: fixed;
  width: 100%;
  bottom: 0;
}
.copyright-home {
  margin-top: 10px;
  height: 50px;
  line-height: 50px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background: white;
  left: 0;
}
.mb-button {
  margin-bottom: 70px;
}
.logoDIv {
  margin-left: -1rem;
}
.logoDIv img {
  max-width: 100px;
}
.vh-100 {
}
.progressBar {
  margin-top: 1em;
  overflow: visible;
  padding-bottom: 1em;
}
.progressing .progress .progress-bar {
  position: relative;
}
.progressing .progress .progress-bar::after {
  position: absolute;
  width: 17px;
  height: 17px;
  background: #ffcd00;
  border-radius: 100px;
  content: "";
  right: 0;
  top: 50%;
  margin-top: -8.5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.46);
}
.progressBar h4 {
  color: #001559;
  text-transform: uppercase;
  font-size: 30px;
  margin-bottom: 1em;
  text-align: center;
  font-family: "Helvetica Neue";
}
.progressBar h4 span {
  /*color: #40C8F4;*/
  color: #e1241b;
}
.progress-bar {
  background-color: #8cc751;
  overflow: hidden;
}
.ProcessingImage img {
  width: 70%;
}
#status-bar {
  position: relative;
  height: 10px;
  margin-bottom: -10px;
  top: -10px;
  transition: opacity 0.5s ease-in;
  background-color: #e2e2e2;
  border-radius: 50px;
  overflow: hidden;
}
#status-bar.fail {
  opacity: 1;
  background-color: red;
}
#progress-bar {
  position: relative;
  right: 0;
  height: 10px;
  margin: auto;
  background: #8cc751;
  background-size: 300% 100%;
  animation: errorBg 2s linear infinite;
  border-radius: 50px;
  z-index: 999;
  display: none;
}
#progress-bar:before {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  background: #ffcd00;
  border-radius: 50px;
  top: -0.3em;
  right: 0;
}
@-moz-keyframes errorBg {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}
@-webkit-keyframes errorBg {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}
@-o-keyframes errorBg {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}
@keyframes errorBg {
  0% {
    background-position: 100%;
  }
  100% {
    background-position: 0%;
  }
}
.infoBar {
  /*margin-top: 2em;*/
  margin-top: 1rem;
  padding: 0 1.5em;
}
.infoBar a {
  color: #001559;
  text-decoration: none;
  font-family: "Helvetica Neue";
}
.infoBar a:hover {
  color: #001559;
}

.infoBar img {
  margin-bottom: 1em;
}
.infoBar p {
  color: #001559;
  font-size: 22px;
}
.copyright-processing {
  position: relative;
  margin: 2em 0 1em;
  left: 0;
  right: 0;
}
.completed {
  text-align: center;
  margin: 20px 0 10px;
}
.completed img {
  max-width: 120px;
}
.completed #progress-bar:before {
  content: none;
}
.completed #progress-bar {
  right: auto !important;
}
.ProcessingImage {
  position: relative;
}
.ProcessingImage span {
  position: absolute;
  display: block;
  background: #8cc751;
  transition: all 0.125s linear;
}

.ProcessingImage .top,
.ProcessingImage .bottom {
  width: 100%;
  height: 5px;
  transform: scaleX(0);
}

.ProcessingImage .left,
.ProcessingImage .right {
  width: 5px;
  height: 100%;
  transform: scaleY(0);
}

.ProcessingImage .top {
  top: 0;
  left: 0;
  transition-delay: 0.375s;
  transform-origin: top left;
}

.progressBar h5 {
  color: #001559;
  text-transform: uppercase;
  font-size: 25px;
  margin-bottom: 1em;
  text-align: center;
  font-family: "Helvetica Neue";
}

.ProcessingImage .right {
  top: 0;
  right: 0;
  transition-delay: 0.25s;
  transform-origin: top left;
}

.ProcessingImage .bottom {
  bottom: 0;
  right: 0;
  transition-delay: 0.125s;
  transform-origin: top right;
}

.ProcessingImage .left {
  top: 0;
  left: 0;
  transform-origin: bottom left;
}

.ProcessingImage:hover .top {
  transition-delay: 0s;
}
.ProcessingImage:hover .right {
  transition-delay: 0.125s;
}
.ProcessingImage:hover .bottom {
  transition-delay: 0.25s;
}
.ProcessingImage:hover .left {
  transition-delay: 0.375s;
}
.ProcessingImage:hover .top,
.ProcessingImage:hover .bottom {
  transform: scaleX(1);
}
.ProcessingImage:hover .left,
.ProcessingImage:hover .right {
  transform: scaleY(1);
}
.selectBox form {
  padding: 0.3em;
}
.selectBox form select {
  width: 100%;
  padding: 0.6em;
  font-size: 1.9rem;
  margin-top: 1em;
  color: #0c4da2;
}
.youtubEVIDEO {
  margin-top: 2em;
}
.ProcessingImage #snap {
  padding: 1em;
  text-transform: uppercase;
  background-color: #8cc751;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  display: block;
  text-align: center;
  font-size: 20px;
  border: none;
}
.canvas {
  width: 100%;
  height: 480px;
  position: relative;
  left: 0;
  top: 0;
  display: none;
  max-height: 480px;
  overflow: hidden;
}
#canvas {
  width: 100%;
}

.progress {
  width: 100%;
  max-width: 300px;
  height: 8px;
  background: #e1e4e8;
  border-radius: 3px;
  overflow: visible;
  margin: 0px auto;
}
.progress .progress-bar {
  display: block;
  height: 100%;
  background: #8cc751;
  overflow: visible;
  border-radius: 3px;
}

:root {
  --color-first: #65587f;
  --color-second: #f18867;
  --color-third: #e85f99;
  --color-forth: #50bda1;
  --block-width: 300px;
  --block-height: 270px;
  --border-width: 0.625rem;
  --border-radius-outer: 8px;
  --border-radius-inner: calc(var(--border-radius-outer) / 2);
  --font-plain: "IBM Plex Sans", sans-serif;
  --font-special: "Fredoka One", sans-serif;
  box-sizing: border-box;
  line-height: 1.4;
}

.rainbow {
  width: 100%;
  height: 100%;
  -webkit-animation: o-rotate-360 linear 8s infinite;
  animation: o-rotate-360 linear 8s infinite;
}
.rainbow span {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  transform: translate(-50%, -50%);
}
.rainbow span:after {
  display: block;
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  left: 100%;
}
.rainbow span:first-child {
  background: var(--color-first);
}
.rainbow span:first-child:after {
  background: var(--color-second);
}
.rainbow span:last-child {
  /*background: #8CC752;*/
  background: #e1241b;
}

.c-subscribe-box {
  width: 100%;
  height: 220px;

  overflow: hidden;
  position: relative;
  box-shadow: 0px 0px 0px 1px #cfcfcf inset;
  -webkit-box-shadow: 0px 0px 0px 1px #cfcfcf inset;
  -moz-box-shadow: 0px 0px 0px 1px #cfcfcf inset;
  border-radius: 18px;
}
.c-subscribe-box__wrapper {
  width: 97%;
  height: 210px;
  position: absolute;
  top: 5px;
  left: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 18px;
  justify-content: center;
  background: #f6f7fa url(../img/camera-fill.svg) no-repeat center center;
  background-size: 70px;
}

@-webkit-keyframes o-rotate-360 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes o-rotate-360 {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bottom-section {
  padding-top: 1rem;
}
.header-style{
  position: "fixed";
  justify-content: "center";
  align-items: "center";
  background-color: "white";
  margin-bottom: "30px";
  width: "100%";
  z-index:"100"
}