.leftDiv {
  position: absolute;

  transform-origin: top left;
  -ms-transform: skew(-30deg, 0deg);
  -webkit-transform: skew(-30deg, 0deg);
  transform: skew(-30deg, 0deg);


}

.imagePreview {
  position: relative;
  max-width: 50%;
  height: auto;
  display: table;
  margin: 0 auto;
}

/* .scanProduct1 p{

	text-transform: uppercase; 
	padding-top: 10px;
    background: #001489;
	color: #fff;

	text-decoration: none;
	width: 60%;
    height: 48px;
    display: block;
    margin-left: 60px;
    
    text-align: center;
    font-size: 16px;
    border-radius:10px 10px 10px 10px;
    font-family: 'Helvetica Neue';
   
} */
/* This used to work for the parent element of button divs */
/* But it does not work with newer browsers, the below doesn't hide the play button parent div */

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.scanProduct1 p {


  padding: 1em;
  text-transform: uppercase;
  /*background-color: #0181C9;*/
  background-color: #1a4e8c;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  width: 100%;
  display: block;
  text-align: center;
  font-size: 20px;
  border-radius: 32pt;
  font-family: 'Helvetica Neue';
 z-index:999;



}

.scanProduct1 img {
  margin-left: 12px;
  height: 60%;
}


/* .textContainer {
    display:"flex";
    flex-direction:"column";
    flex-wrap:"wrap";
    align-content:"space-between";
    text-align:"center";
    color:"rgba(255, 255, 255, 1)";
    font-Size:"10px";
    font-family: "'Share Tech', sans-serif";
    
} */

/* Portrait and Landscape */
.main-text {
  color: #64B245;
}

.header-style{
  position: "fixed";
  justify-content: "center";
  align-items: "center";
  background-color: "white";
  margin-bottom: "30px";
  width: "100%";
  z-index:"100"
}

@media only screen and (min-device-width: 335px) and (max-device-width: 667px) and (orientation : portrait) {

  .main-text {
    color: #64B245;
    font-size: 20px;
  }

  .para-text {
    font-size: 20px;
  }
  .topLogo{
  max-width: "100px";
  height: "1020px";
  margin-bottom: "10px",
  }
}
.headerStyle{
position: "fixed";
justify-content: "center";
align-items: "center";
background-color: "white";
margin-bottom: "30px";
width: "100%";
z-index:"100"

};
.leftLine{
background-color: "#001489";
margin-right: "90%";
float: "left";
width: "70%";
height: "7px";
padding-right:"-120px";
position: "absolute";



};
.rightLine{
background-color: "#E63227";
float: "right";
width: "35%";
height: "7px";

left: "30%";
};
.topLogo{
max-width: " 100px";
height: "120px";

margin-bottom: "10px";
};

.headText{
display: 'flex';
flex-direction:'column';
padding-right:'12px';
text-align: "left";
margin-right:'52px';
margin: "25px ";
margin-left:'15px'

};
.mainText{

margin-right:'-36px';
font-size: "25px";
font-family:'Helvetica Neue';
font-weight:"bold";
color: "#E2261D";
width:"auto"

};
.paraText{

position: "absolute";
font-family:'Helvetica Neue';
color: "#083668";
font-size:"26px";
font-weight:"bold";
text-align:"left"
};
