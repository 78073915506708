.img-div {
  border-radius: 0.5rem;

 
}
.img-div .scanning {
  left: 0;
  width: 0.2rem;
  background-color: #E01616;
  box-shadow: -5px 0 10px 1px #E01616;
  animation: scan 4s ease-in-out infinite;
}
@keyframes scan {
  0% {
    left: 1%;
    box-shadow: -5px 0 10px 1px #E01616;
 }
  45% {
    box-shadow: -5px 0 10px 1px #E01616;
 }
  50% {
    left: 99%;
    box-shadow: 5px 0 10px 1px #E01616;
 }
  95% {
    box-shadow: 5px 0 10px 1px #E01616;
 }
  100% {
    left: 1%;
    box-shadow: -5px 0 10px 1px #E01616;
 }
}
.img-div .blink-txt {
  z-index: 1;
  background-color: transparent;
  opacity: 0;
  animation: txt_blink 2.2s linear 0.5s 1;
  
 
}
.img-div .blink-txt h2 {
  font-size: 2.8rem;
}
@keyframes txt_blink {
  0% {
    opacity: 0;
    background-color: transparent;
 }
  20% {
    opacity: 1;
    background-color: #000 80;
 }
  70% {
    opacity: 1;
    background-color: #000 80;
 }
  100% {
    opacity: 0;
    background-color: transparent;
 }
}
.image{
  height: 100%; width: 100%; object-fit: contain;
}
